import React, { Component } from 'react';
import { AppStateConsumer } from '../../providers/AppState';
import api from '../../configs/api';

class Thumbnail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadable: !!this.src
    }
  }

  get src() {
    if (this.props.settingsLoadingSuccess && this.props.previewSettings.overRideThumbnails.active && this.props.type === 'asset') {
      const metadataField = this.props.previewSettings.overRideThumbnails[this.props.listType];
      if (this.props.asset.metadata[metadataField]) {
        return api.getPreviewURL(this.props.asset.metadata[metadataField]);
      }
    }
    
    return this.props.src;
  }

  isLoadable() {
    if (this.src) {
      var image = new Image();
      image.onerror = () => {
        this.setState({
          isLoadable: false
        });
      };
      image.src = this.src;
    }
  }

  componentWillMount() {
    this.isLoadable();
  }

  get fallback() {
    let fallback = this.props.settingsLoadingSuccess ? this.props.previewSettings.fallbackImages[this.props.type] : {};
    let fallbackPreviewID;
    if (this.props.type === 'asset') {
      let assetDomain = this.props.asset.metadata.assetDomain;
      fallbackPreviewID = fallback[assetDomain] ? fallback[assetDomain] : fallback['others'];
    } else {
      fallbackPreviewID = fallback.id;
    }

    return this.props.token ? api.getPrivatePreviewURL(fallbackPreviewID, this.props.token) : api.getPreviewURL(fallbackPreviewID);
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={{ backgroundImage: this.src ? `url(${this.src})` : this.fallback ? `url(${this.fallback})` : '' }}>
      </div>
    );
  }
}

const ConnectedThumbnail = props => (
  <AppStateConsumer>
    {({ settingsLoadingSuccess, previewSettings }) => (
      <Thumbnail
        {...props}
        previewSettings={previewSettings}
        settingsLoadingSuccess={settingsLoadingSuccess}
      />
    )}
  </AppStateConsumer>
);

export default ConnectedThumbnail;