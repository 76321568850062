import React, { Component } from 'react';
import api from '../../configs/api';
import elvisApi from '../../helpers/elvisApi';
import AssetList from '../AssetList/AssetList';

class HierarchyCollectionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: {
        assets: [],
        totalHits: 0
      },
      
      ui: {
        assetsLoading: false,
        assetsLoadingSuccess: false,
      },

      basketList: localStorage.hierarchiesBasket ? JSON.parse(localStorage.hierarchiesBasket) : []
    }
  }

  toggleBasket(id) {
    let basketList = localStorage.hierarchiesBasket ? JSON.parse(localStorage.hierarchiesBasket) : [];
 
    if (basketList.includes(id)) {
      basketList = basketList.filter(item => item !== id);
    } else {
      basketList.push(id);
    }

    this.setState({ basketList });
    localStorage.hierarchiesBasket = JSON.stringify(basketList);
  }

  async componentDidMount() {
    await this.loadAssets();
  }

  async loadAssets() {
    this.setState({
      ui: {
        ...this.state.ui,
        assetsLoading: true,
        assetsLoadingSuccess: false,
      },
      result: {
        ...this.state.result,
        assets: [],
        totalHits: 0
      }
    });

    try {
      const requestApi = this.props.settings.general.useElvisProxyForAll ? api : elvisApi;
      const result = await requestApi.search({ q: `id:${this.props.collectionId}` });

      this.setState({
        ui: {
          ...this.state.ui,
          assetsLoading: false,
          assetsLoadingSuccess: true,
        },
        result: {
          ...this.state.result,
          assets: result.hits.map(hit => ({
            id: hit.id,
            name: hit.metadata.name,
            thumbnail: hit.thumbnailUrl ? api.getThumbnailURL(hit.id) : hit.thumbnailHits && hit.thumbnailHits.length > 0 ? api.getThumbnailURL(hit.thumbnailHits[0].id) : null,
            metadata: hit.metadata
          })),
          totalHits: result.totalHits,
        }
      });
    } catch(error) {
      console.log(error);      
    }
  }

  render() {
    return (
      <div className='ems-hierarchy-collection-list'>
        <AssetList
          itemPath={elvisApi.csrfToken ? 'item' : 'share-item'}
          ui={this.state.ui}
          type='hierarchy'
          settings={this.props.settings}
          assetHeadline={<div></div>}
          totalItem={this.state.result.totalHits}
          hideSorting={true}
          assetList={this.state.result.assets} 
          basketList={this.state.basketList}
          toggleBasket={id => this.toggleBasket(id)}/>
      </div>
    );
  }
}

export default HierarchyCollectionList;