import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import './SearchBar.css';
import GoogleAnalytics from 'react-ga';

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: this.props.keyword,
    };
  }

  onChange(e) {
    this.setState({
      keyword: e.target.value,
    });
  }

  submitForm(e) {
    e.preventDefault();
    GoogleAnalytics.event({
      category: 'Search',
      action: `User performed a search`,
      label: `keyword:${this.state.keyword} | date:${(new Date()).toDateString()}`,
    });
    this.props.search(this.state.keyword);
  }

  componentDidUpdate(prevProps) {
    const prevQueryObj = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true });
    const currentQueryObj = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    if (prevQueryObj.keyword !== currentQueryObj.keyword) {
      this.setState({
        keyword: currentQueryObj.keyword || '',
      });
    }
  }

  render() {
    return (
      <div className='ems-margin-vertical ems-search-bar'>
        <form className='ems-search-bar-form' onSubmit={this.submitForm.bind(this)}>
          <input 
            type='text' 
            value={this.state.keyword} 
            onChange={this.onChange.bind(this)} 
            className='ems-input ems-input-text ems-search-bar-input' 
            placeholder={this.props.placeholder ? this.props.placeholder : 'What are you looking for today?'}/>
          {this.props.keyword && 
            <button
              type='reset'
              onClick={() => { this.setState({ keyword: '' }, () => this.props.reset())  }}
              className='ems-button ems-search-bar-clear-button'>
              <i className='far fa-times-circle'></i>
            </button>}
          <button className='ems-button ems-search-bar-button' type='submit'>
            {this.props.buttonText ? this.props.buttonText : <i className='fa fa-search'></i>}
          </button>
        </form>
      </div>
    );
  }
}

export default withRouter(SearchBar);