import React from "react";
import PropTypes from "prop-types";
import ShareLoginForm from "./shareLoginForm";
import { useHistory, useParams } from "react-router-dom";
import { usePrivateShare } from "./hooks";

const ShareLogin = () => {
  const shareLoginMutation = usePrivateShare();
  const history = useHistory();
  const { token } = useParams();

  if (!shareLoginMutation.isSuccess && sessionStorage.getItem(token)) {
    history.push(`/shares/private/${token}`)
  }

  const onSubmit = (data) => {
    const credentials = {
      password: data.sharePassword,
      token: token,
    };

    shareLoginMutation.mutate(credentials, {
      onSuccess: () => {
        history.push(`/shares/private/${token}`)
      }
    });
  };

  return (
    <div className="ems-sign-in-to-share ems-sign-in ems-main">
      <div className="ems-main-inner">
        <ShareLoginForm
          onSubmit={onSubmit}
          requestStatus={{
            isLoading: shareLoginMutation.isLoading,
            isError: shareLoginMutation.isError,
            isSuccess: shareLoginMutation.isSuccess,
            error: shareLoginMutation.error
          }}
        />
      </div>
    </div>
  );
};

ShareLogin.defaultProps = {};

ShareLogin.propTypes = {};

export default ShareLogin;
