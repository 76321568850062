import React, { Component } from 'react';
import api from '../../configs/api';
import './HierarchyBanner.css';
import Carousel, { consts } from 'react-elastic-carousel';

class HierarchyBanner extends Component {
  _getURL(platformObject) {
    if (platformObject.elvis) {
      return api.getPreviewURL(platformObject.elvis.id);
    }
    return platformObject.url;
  }

  getBackgroundURL(platform, banner) {
    switch (platform) {
      case 'desktop':
        return this._getURL(banner);
        break;
      case 'laptop':
        return this._getURL(banner);
        break;
      case 'tablet':
        return this._getURL(banner);
        break;
      case 'largePhone':
        return this._getURL(banner);
        break;
      case 'mediumPhone':
        return this._getURL(banner);
        break;
      case 'smallPhone':
        return this._getURL(banner);
        break;
    }
  }

  arrows({ type, onClick, isEdge }) {
    return (
      <button 
        onClick={onClick}
        disabled={isEdge}
        className={`ems-button ems-button-icon ${type === consts.PREV ? 'ems-banner-carousel-back-button' : 'ems-banner-carousel-next-button'}`}>
          <i className={`fas ${type === consts.PREV ? 'fa-chevron-left' : 'fa-chevron-right'}`}></i>
      </button>
    );
  }

  render() {
    return (
      <div className='ems-hierarchy-banner'>
      <Carousel
        className='ems-hierarchy-banner-carousel'
        pagination={false}
        renderArrow={this.arrows}>
          {this.props.banner.desktop.map((banner, index) => 
            <a href={banner.url} key={index}>
              <img className='ems-hierarchy-banner-image ems-hierarchy-banner-image-desktop' src={this.getBackgroundURL('desktop', banner)}/>
            </a>
          )}
      </Carousel>
        {/* {this.props.banner.laptop.map(banner => <img className='hierarchy-banner-image hierarchy-banner-image-laptop' src={this.getBackgroundURL('desktop', banner)}/>)}
        {this.props.banner.tablet.map(banner => <img className='hierarchy-banner-image hierarchy-banner-image-tablet' src={this.getBackgroundURL('desktop', banner)}/>)}
        {this.props.banner.largePhone.map(banner => <img className='hierarchy-banner-image hierarchy-banner-image-largePhone' src={this.getBackgroundURL('desktop', banner)}/>)}
        {this.props.banner.mediumPhone.map(banner => <img className='hierarchy-banner-image hierarchy-banner-image-mediumPhone' src={this.getBackgroundURL('desktop', banner)}/>)}
        {this.props.banner.smallPhone.map(banner => <img className='hierarchy-banner-image hierarchy-banner-image-smallPhone' src={this.getBackgroundURL('desktop', banner)}/>)} */}
      </div>
    );
  }
}

export default HierarchyBanner;