import article from './article.json';
import assetItemMetadataList from './asset-item-metadata-list.json';
import assetsSortingList from './assets-sorting-list.json';
import assetViewMetadataList from './asset-view-metadata-list.json';
import general from './general.json';
import header from './header.json';
import shareFilterList from './share-filter-list.json';
import style from './style.json';

export default {
  'article': article,
  'asset-item-metadata-list': assetItemMetadataList,
  'assets-sorting-list': assetsSortingList,
  'asset-view-metadata-list': assetViewMetadataList,
  'general': general,
  'header': header,
  'share-filter-list': shareFilterList,
  'style': style,
};