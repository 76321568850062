import configs from '../configs/config';
import cookies from './cookies';

function handleResponse(response) {
  return new Promise((resolve, reject) => {
    response.json().then(jsonBody => {
      if (response.ok && !jsonBody.errorcode) {
        return resolve(jsonBody);
      }

      if (jsonBody.errorcode == 401) {
        window.location.replace('/#/sign-out');
      }

      return reject({
        status: response.status,
        headers: response.headers,
        body: jsonBody,
      });
    })
      .catch(() => reject(response));
  });
}

const Elvis = {
  csrfToken: cookies.get('branding-wwa-csrfToken') || null,
  authToken: cookies.get('branding-wwa-authToken') || null
}

Elvis.header = function (customHeader) {
  let headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  if (Elvis.authToken) {
    headers['Authorization'] = `Bearer ${Elvis.authToken}`;
  }

  if (Elvis.csrfToken) {
    headers['x-csrf-token'] = Elvis.csrfToken;
  }

  if (customHeader) {
    headers = {
      ...headers,
      ...customHeader
    };
  }

  return headers;
}

Elvis.download = function (ids, fileName = 'download') {
  window.open(`${configs['elvis.url']}/zip/${fileName}?downloadKind=original&assetIds=${ids}`);
}

Elvis.search = function (params) {
  params.appendRequestSecret = true;

  return fetch(`${configs['elvis.url']}/services/search`, {
    method: 'post',
    mode: 'cors',
    credentials: 'include',
    body: new URLSearchParams(params),
    headers: Elvis.header(),
  })
    .then(handleResponse);
}

Elvis.login = function (params) {
  return fetch(`${configs['elvis.url']}/services/login`, {
    method: 'post',
    mode: 'cors',
    credentials: 'include',
    body: new URLSearchParams(params),
    headers: Elvis.header(),
  })
    .then(response => {
      return handleResponse(response).then(body => {
        const sessionExpiry = new Date();
        sessionExpiry.setTime(sessionExpiry.getTime() + (1000 * 60 * 60 * 24 * 3));
        cookies.set('branding-wwa-csrfToken', body.csrfToken, { expires: sessionExpiry });
        Elvis.csrfToken = body.csrfToken
      })
    });
}

Elvis.logout = function (params) {
  Elvis.csrfToken = null
  Elvis.authToken = null
}

export default Elvis;