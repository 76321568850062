import defaultSettings from './defaultSettings/defaultSettings';

export default function validateSettings(settings) {
  if (!settings) {
    return { error: 'Settings needs to be provided.', payload: defaultSettings };
  }

  const missingSettings = 
    Object.keys(defaultSettings)
      .map(name => {
        const defaultSettingObject = defaultSettings[name];
        const comparedSetting = settings.find(setting => setting.name === name);

        if (comparedSetting) {
          const comparedSettingObject = comparedSetting.config;
          const missingFields = Object.keys(defaultSettingObject).filter(defaultFieldName => typeof comparedSettingObject[defaultFieldName] === 'undefined');

          let suggestedConfig = {
            ...comparedSettingObject,
          };

          missingFields.forEach(fieldName => {
            suggestedConfig[fieldName] = defaultSettingObject[fieldName];
          })

          return {
            name,
            missingFields,
            suggestedConfig, 
          };
        }

        return {
          name,
          missingFields: Object.keys(defaultSettingObject),
          suggestedConfig: defaultSettingObject,
        }
      })
      .filter(missingSetings => missingSetings.missingFields.length > 0);

  return missingSettings;
}

export function logMissingSettings(missingSetings) {
  missingSetings.forEach(missingSeting => {
    console.group("%cMISSING SETTING", 'background: blue; padding: 10px 300px; font-size: x-large; color: white');
    console.log(
      `%cSETTING NAME: ${missingSeting.name} | MISSING FIELDS: ${missingSeting.missingFields.join(', ')}`
    , 'background: brown; padding: 10px; font-size: large;');
    console.group('%cNEW SUGGESTED SETTING OBJECT:', 'background: blue; padding: 10px 602px 10px 10px; color: white');
    console.dir(missingSeting.suggestedConfig);
    console.groupEnd('NEW SUGGESTED CONFIG OBJECT');
    console.groupEnd('MISSING SETTING');
    console.log('%cMISSING SETTING', 'background: blue; padding: 10px 300px; font-size: x-large')
  });
}
