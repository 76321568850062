import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSetNewPassword } from "./hooks";

const SetNewPassword = () => {
  const {
    register,
    formState: { isValid },
    handleSubmit,
  } = useForm({ mode: "onChange" });
  const { code } = useParams();
  const history = useHistory();
  const setNewPasswordMutation = useSetNewPassword();

  const onSubmit = (data) => {
    setNewPasswordMutation.mutate({ code, ...data }, {
      onSuccess: () => {
        setTimeout(() => {
          history.push("/auth");
        }, 2000);
      },
    });
  };

  return (
    <form className="ems-reset-password-form" onSubmit={handleSubmit(onSubmit)}>
      {!setNewPasswordMutation.isSuccess && (
        <React.Fragment>
          <h2 className="ems-margin-bottom ems-reset-password-form-title">
            Set New Password
          </h2>
          {setNewPasswordMutation.isError && (
            <p className="error ems-reset-password-form-error">
              {setNewPasswordMutation?.error?.body?.data[0]?.messages[0]?.message ||
                "An unknown error occurred."}
            </p>
          )}
          <input
            type="password"
            {...register("password", {
              required: "Password is required",
              minLength: 5,
            })}
            className="ems-input ems-input-text ems-margin-bottom-half ems-reset-password-form-input"
            placeholder="Password"
          />
          <input
            type="password"
            {...register("passwordConfirmation", {
              required: "Password is required",
              minLength: 5,
            })}
            className="ems-input ems-input-text ems-margin-bottom-half ems-reset-password-form-input"
            placeholder="Repeat New Password"
          />
          <button
            disabled={!isValid}
            type="submit"
            className="ems-button ems-reset-password-form-button"
          >
            Submit
          </button>
        </React.Fragment>
      )}
      {setNewPasswordMutation.isSuccess && (
        <p>Your password has been updated. Redirecting to login.</p>
      )}
    </form>
  );
};

SetNewPassword.defaultProps = {};
SetNewPassword.propTypes = {};

export default SetNewPassword;
