import configs from '../configs/config';

export default {
  isEnabled: () => (
    !!configs['okta.url'] && !!configs['okta.clientId']
  ),

  constructURL: () => (
    encodeURI(
      `${configs['okta.url']}/oauth2/v1/authorize?client_id=${configs['okta.clientId']}` +
      `&response_type=code` +
      `&scope=openid profile email groups` +
      `&redirect_uri=${configs['branding.url']}/auth/okta&state=${Math.random().toString(36).substring(2)}`
    )
  )
}
