import React, { Component } from 'react';
import screenfull from 'screenfull';
import ReactPlayer from 'react-player';
import api from '../../configs/api';
import './Player.css';
import GoogleAnalytics from 'react-ga';

class Player extends Component {
  constructor(props) {
    super(props);
    this.element = React.createRef();
    this.state = {
      url: '',
      playing: typeof props.playing === 'undefined' ? true : props.playing,
      fullscreen: false,
      played: 0,
      loaded: 0,
      duration: 0,
      buffering: true,
    }
  }

  ref = player => {
    this.player = player
  }
  
  get stream () {
    return (
      this.props.share &&
      this.props.share.stream &&
      Object.keys(this.props.share.stream).filter(metadata => this.props.share.stream[metadata].includes(this.props.asset.metadata[metadata])).length
    );
  }

  formatDuration(seconds) {
    function pad(string) {
      return ('0' + string).slice(-2)
    }

    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = pad(date.getUTCSeconds())
    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`;
    }
    return `${mm}:${ss}`;
  }

  get timeElaped() {
    return this.formatDuration(this.state.duration * this.state.played);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.asset.id !== this.props.asset.id) {
      this.setState({
        buffering: true,
      });
    }
  }

  componentDidMount() {
    this.setURL();
    this.element.current.addEventListener('contextmenu', this.handleContextMenu);
  }

  toggleFullscreen = () => {
    screenfull.toggle(this.element.current);
    this.setState({ fullscreen: !this.state.fullscreen });
  }

  togglePlayPause = () => {
    this.setState({ playing: !this.state.playing });

    GoogleAnalytics.event({
      category: 'Player',
      action: `${this.state.playing ? 'Paused' : 'Played'} video.`,
      label: `asset id:${this.props.asset.id} | video time elapsed: ${this.timeElaped} | date:${(new Date()).toDateString()}`,
    });
  }

  handleSeekMouseDown = e => {
    this.setState({ seeking: true })
  }

  handleDuration = duration => {
    this.setState({ duration });
  }

  handleSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) })
  }

  handleSeekMouseUp = e => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e.target.value))
  }

  handleProgress = state => {
    if (!this.state.seeking) {
      this.setState({
        played: state.played,
        loaded: state.loaded,
      });
    }
  }

  handleReady = () => {
    this.setState({
      buffering: false,
    });
  }

  handleBuffer = () => {
    this.setState({
      buffering: true,
    });
  }

  handleBufferEnd = () => {
    this.setState({
      buffering: false,
    });
  }

  componentWillUnmount() {
    GoogleAnalytics.event({
      category: 'Player',
      action: `User closing video.`,
      label: `asset id:${this.props.asset.id} | date:${(new Date()).toDateString()}`,
    });
    this.element.current.removeEventListener('contextmenu', this.handleContextMenu);
  }

  handleContextMenu(e) {
    e.preventDefault();
  }

  componentDidUpdate(prevProps) {
    if (this.props.asset.id !== prevProps.asset.id) {
      this.setURL();
    }
  }

  async setURL() {
    if(this.stream) {
      let response = (
        this.props.token ?
        await api.getPrivateFileHLS(this.props.token, this.props.asset.id) :
        await api.getSharedFileHLS(this.props.share, this.props.asset.id)
      )
      if (response.url) {
        this.setState({
          url: response.url,
          message: ''
        })
      } else if (response.message) {
        this.setState({ message: response.message })
        setTimeout(this.setURL.bind(this), 120000);
      }
    } else {
      this.setState({
        url: this.props.previewUri,
        message: ''
      })
    }
  }

  handleError(error, hlsError) {

    if (hlsError && hlsError.type == 'mediaError') {
      console.warn(hlsError)
      return
    }

    if(error.name === 'NotAllowedError') {
      this.setState({
        playing: false,
      })
    }

    if (this.props.onError) {
      this.props.onError(error);
    } else {
      console.error('Video play error');
    }
  }

  render() {
    return (
      <div className='ems-player-container' ref={this.element}>
        {this.state.buffering && !this.state.message && <div className='ems-player-buffering'>Loading...</div>}
        {this.state.url && (
          <ReactPlayer
            onError={(error, hlsError) => this.handleError(error, hlsError)}
            ref={this.ref}
            onBuffer={this.handleBuffer}
            onBufferEnd={this.handleBufferEnd}
            onProgress={this.handleProgress}
            onReady={this.handleReady}
            onContextMenu={() => false}
            controls={false}
            url={this.state.url}
            playing={this.state.playing}
            onDuration={this.handleDuration}
            onEnded={() => this.props.onEnded && this.props.onEnded()}
            config={{
              file: {
                poster: api.getAssetThumbnail(this.props.asset, this.props.token),
                attributes: {
                  onContextMenu: e => e.preventDefault(),
                  controlsList: 'nodownload'
                },
                hlsOptions: {
                  enableWorker: true,
                  lowLatencyMode: true,
                  backBufferLength: 90,
                  //pLoader: (context, config) => {
                  //  console.log(context, config)
                  //},
                  xhrSetup: (xhr, url) => {
                    //xhr.withCredentials = true // send signed cookies
                    //console.log('this.state.url', this.state.url)
                    //xhr.setRequestHeader('Access-Control-Allow-Headers','Content-Type, Accept, X-Requested-With');
                    //xhr.setRequestHeader('Access-Control-Allow-Origin','*');
                    //xhr.setRequestHeader('Access-Control-Allow-Credentials','true');
                    //xhr.withCredentials = true // send signed cookies
                    if (!url.includes('?')) {
                     // xhr.open('GET', `${url}?${this.state.url.split('?')[1]}`, true);
                    }
                    //console.log('xhr.beforeRequest', xhr.beforeRequest)
                    //xhr.responseURL = xhr.responseURL + '?mani=pulate'
                    //url = url + '?hede=da'
                    //xhr.withCredentials = true // send signed cookies
                  }
                }
              }
            }} 
            width='100%' 
            height='100%'/>
         )}
         {!this.state.buffering && <div className='ems-player-controls'>
          <button className='ems-player-controls-button' onClick={this.togglePlayPause}>
            {!this.state.playing && <i className='fas fa-play'></i>}
            {this.state.playing && <i className='fas fa-pause'></i>}
          </button>
          <div className='ems-player-controls-time'>{this.timeElaped} / {this.formatDuration(this.state.duration)}</div>
          <div className='ems-player-controls-progress'>
            <input
              className='ems-player-controls-progress-range'
              type='range' min={0} max={0.999999} step='any'
              value={this.state.played}
              onMouseDown={this.handleSeekMouseDown}
              onChange={this.handleSeekChange}
              onMouseUp={this.handleSeekMouseUp}
            />
            <div className='ems-player-controls-progress-bar' style={{ width: `calc(${this.state.played} * (100% - 20px))` }}/>
            <div className='ems-player-controls-loaded-bar' style={{ width: `calc(${this.state.loaded} * (100% - 20px))` }}/>
          </div>
          <button className='ems-player-controls-button ems-player-controls-button-fullscreen' onClick={this.toggleFullscreen}>
            {!this.state.fullscreen && <i className='fas fa-expand'></i>}
            {this.state.fullscreen && <i className='fas fa-compress'></i>}
          </button>
        </div>}
        {this.state.message && <div className='ems-player-container-message'>{this.state.message}</div>}
      </div>
    );
  }
}

export default Player;
