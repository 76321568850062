import React, { Component } from 'react';
import './Article.css';
import api from '../../configs/api';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class ItemView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      article: {},
      images: [],
      generalSiteSettings: {
        siteName: '',
        footerHTML: '',
      },
    };
  }

  reset() {
    this.setState({
      article: {},
    });
  }

  fetchData() {
    this.reset();

    api.getArticleImages(this.props.match.params.id).then(images => {
      this.setState({ images });

      api.getArticle(this.props.match.params.id).then(article => {
        this.setState({ article });
      })
    });

    api.getSettings().then(settings => {
      this.setState({
        assetMetadataList: settings.find(({ name }) => name === 'asset-view-metadata-list').config.list,        
        generalSiteSettings: settings.find(({ name }) => name === 'general').config,
      })
    });
  }

  articleHTML() {
    const html = this.state.article.html.html;

    const htmlWithActualImages = html.replace(new RegExp(/(img\/([a-z\-_0-9\/\:\.]*)\.(jpg|jpeg|png|gif))/i, 'g'), (...args) => {
      const image = this.state.images.find(image => image.sceId == args[2]);
      if (image)
        return api.getPreviewURL(image.id);
      else
        return args[0];
    });

    return {__html: htmlWithActualImages};
  }

  articleCSS() {
    return this.state.article.html.css;
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <div className='ems-container'>
        <Header
          settings={this.state.generalSiteSettings}
          isAuthenticated={true}
          basketList={this.state.basketList}/>
        <div className='ems-item-view ems-article-view ems-main'>
          <div className='ems-main-inner'>
            <div className='ems-item-view-navigation ems-article-view-navigation'>
              <button className="ems-button" href='#' onClick={e => { e.preventDefault(); this.props.history.goBack(); }}><i className='fas fa-arrow-left'></i> Back</button>
            </div>
            {this.state.article.html && <div className='ems-article-wrapper' dangerouslySetInnerHTML={this.articleHTML()}></div>}
            {this.state.article.html && <style>{this.state.article.html.css}</style>}
            
          </div>
        </div>
        <Footer settings={this.state.generalSiteSettings}/>
      </div>
    );
  }
}

export default ItemView;
