import React, { Component } from 'react';
import './AssetList.css';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import omit from 'lodash/fp/omit';
import AssetListItem from './AssetListItem/AssetListItem';
import AssetListCollectionItem from './AssetListCollectionItem/AssetListCollectionItem';
import GoogleAnalytics from 'react-ga';

class AssetList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilters: false,
      activeFilters: {},
    };
  }

  handleChangeSorting(event) {
    const newDefaultOrder = this.props.settings.sortingList.find(sorting => sorting.metadata === event.target.value).defaultOrder;
    const newOrder = newDefaultOrder ? (newDefaultOrder === 'default' ? 'desc' : newDefaultOrder) : 'desc';
    this.props.onChangeSorting(`${event.target.value}-${newOrder}`);
  }

  handleChangeSortingOrder(event) {
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const sorting = queryParams.sortBy ? queryParams.sortBy.split('-')[0] : this.props.settings.sortingList[0].metadata;
    this.props.onChangeSorting(`${sorting}-${event.target.value}`);
  }

  toggleFilters(e) {
    e.preventDefault();

    this.setState({
      showFilters: !this.state.showFilters,
    });
  }

  getActiveFilters() {
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const queryParamFilters = {};

    if (queryParams.filters) {
      Object.keys(queryParams.filters).forEach(filterName => {
        queryParamFilters[filterName.split('.')[1]] = queryParams.filters[filterName];
      });
    }

    this.setState({
      activeFilters: queryParamFilters
    });
  }

  componentDidUpdate(prevProps) {
    const prevQueryParams = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true });
    const currentQueryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    if (prevProps.location.search !== this.props.location.search){
      this.getActiveFilters();
    }

    if (prevProps.location.pathname !== this.props.location.pathname || prevQueryParams.viewCollection !== currentQueryParams.viewCollection) {
      this.setState({
        showFilters: false,
      });
    }
  }

  componentDidMount() {
    this.getActiveFilters();
  }

  toggleFilter(metafield, value) {
    const { activeFilters } = this.state;

    let newFilterValue;

    if (activeFilters[metafield]) {
      newFilterValue = activeFilters[metafield].includes(value) ? activeFilters[metafield].filter(activeFilter => activeFilter !== value) : [...activeFilters[metafield], value];
    } else {
      newFilterValue = [value];
    }

    const newActiveFilters = { ...activeFilters, [metafield]: newFilterValue };

    this.setState({
      activeFilters: newActiveFilters,
    });

    GoogleAnalytics.event({
      category: 'Filter',
      action: `User performed a filter`,
      label: `filter:(${metafield}=${newFilterValue.join(',')}) | date:${(new Date()).toDateString()}`,
    });

    this.props.onChangeFilters(newActiveFilters);
  }

  render() {
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const [sortBy, sortOrder] = queryParams.sortBy ? queryParams.sortBy.split('-') : [this.props.settings.sortingList && this.props.settings.sortingList[0],this.props.settings.sortingList[0].defaultOrder];
    const assetQueryParams = qs.stringify(omit(['keyword', 'page', 'viewCollection'],queryParams), { addQueryPrefix: true });

    return (
      <div className='ems-asset-results-wrapper'>
        <div className='ems-heading-wrapper ems-asset-results-heading-wrapper'>
          {this.props.assetHeadline ? this.props.assetHeadline : <h3 className='ems-heading ems-asset-results-heading'>Assets {this.props.totalItem && <span className='ems-asset-results-heading-count'>{this.props.totalItem}</span>}</h3>}
        </div>


        {this.props.ui.settingsLoadingSuccess && this.props.assetList.length > 0 && this.props.settings.sortingList.length > 0 && !this.props.hideSorting && 
        <div className='ems-margin-top-quarter ems-sorting-wrapper'>
          <div className='ems-sorting-title-wrapper'>
            <div className='ems-h5 ems-title ems-sorting-title'><i className={`fas fa-sort-amount-${sortOrder === 'asc' ? 'up' : 'down'}`}></i>Sort</div>
          </div>
          <select className='ems-sorting-selector ems-sorting-field' value={sortBy} onChange={this.handleChangeSorting.bind(this)}>
            {this.props.settings.sortingList.map(item => <option className='ems-sorting-selector-value' key={item.name} value={item.metadata}>{item.name}</option>)}
          </select>
          <select className='ems-sorting-selector ems-sorting-direction' value={sortOrder} onChange={this.handleChangeSortingOrder.bind(this)}>
            <option className='ems-sorting-selector-value' value='asc'>Ascending</option>
            <option className='ems-sorting-selector-value' value='desc'>Descending</option>
          </select>
        </div>}

        <div className='ems-filter-wrapper'>
          <div className='ems-heading-wrapper ems-filter-heading-wrapper'>
            {this.props.ui.settingsLoadingSuccess && this.props.filters.length > 0 && this.props.assetList.length > 0 && <a title={`${this.state.showFilters ? 'Hide' : 'Show'} filters`} className={`ems-button ems-button-icon ems-filter-toggle ${this.state.showFilters ? 'ems-filters-toggle-open' : ''}`} href='/' onClick={e => this.toggleFilters(e)}><i className="fas fa-filter"></i>{this.props.settings.style.filter.button.text || 'Filters'}</a>}
          </div>

          {this.props.ui.settingsLoadingSuccess && this.state.showFilters && this.props.filters.length > 0 && this.props.assetList.length > 0 && 
            <div className='ems-filter-list'>
              <div className='ems-filter-list-inner'>
                {this.props.filters.map((filter, index) => (
                  <div className='ems-filter-list-item' key={index}>      
                    <div className='ems-title ems-filter-list-item-title'>{filter.name}</div>
                    <div className='ems-filter-list-item-values'>
                      {this.props.facets && this.props.facets[filter.metafield] && this.props.facets[filter.metafield].map(facet => (
                        <label className='ems-filter-list-item-value' key={facet.value}>
                          <div className='ems-filter-list-item-value-inner'>
                            <input className='ems-checkbox ems-filter-list-item-value-checkbox' type='checkbox' onChange={() => this.toggleFilter(filter.metafield, facet.value)} checked={!!this.state.activeFilters[filter.metafield] && this.state.activeFilters[filter.metafield].includes(facet.value)}/><span className='ems-filter-list-item-value-text'>{facet.value}</span>
                          </div>
                        </label>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
        </div>

        {this.props.playableAssets && <button className='ems-button ems-margin-top' onClick={() => this.props.togglePlayAll()}><i className="fas fa-play"></i> Play All</button>}

        {this.props.ui.assetsLoadingSuccess && this.props.assetList.length === 0 && !this.props.ui.assetsLoading && <p className='ems-margin-top ems-margin-bottom ems-message ems-asset-list-message'>There are no items to display</p>}
        {this.props.ui.assetsLoading && <p className='ems-margin-top ems-margin-bottom ems-message ems-asset-list-message'>Loading <i className="fas fa-circle-notch fa-spin"></i></p>}
        <div className='ems-asset-list-wrapper'>
          <div className='ems-asset-list'>
            {!this.props.ui.assetsLoading && this.props.ui.assetsLoadingSuccess && this.props.assetList.map(asset => {
              if(asset.metadata.assetDomain === 'container') {
                return <AssetListCollectionItem {...this.props} key={asset.id} id={asset.id} collection={asset} assetQueryParams={assetQueryParams}/>
              } else {
                return (
                  <AssetListItem
                    relatedHierarchy={this.props.relatedHierarchy}
                    token={this.props.token}
                    activeShare={this.props.activeShare}
                    type={this.props.type}
                    assetQueryParams={assetQueryParams}
                    collectionInURLQuery={this.props.collectionInURLQuery}
                    assetMetadataList={this.props.settings.assetMetadataList}
                    key={asset.id}
                    asset={asset}
                    toggleBasket={this.props.toggleBasket}
                    addedToBasket={this.props.basketList.includes(asset.id)}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AssetList);