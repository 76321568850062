import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import qs from 'qs';
import api from '../../../configs/api';
import './FolderListItem.css';
import GoogleAnalytics from 'react-ga';

class FolderListItem extends Component {
  get downloadURL() {
    if (this.props.token) {
      return api.getPrivateDownloadURL(this.props.token);
    } else if (this.props.activeShare) {
      return api.getSharedDownloadURL(this.props.activeShare);
    } else {
      return api.getDownloadURL();
    }
  }

  download(e) {
    e.preventDefault();
    GoogleAnalytics.event({
      category: 'Download',
      action: `Downloaded a folder from shares`,
      label: `folder path:/${this.props.folder.path} | date:${(new Date()).toDateString()}`,
    });
    window.open(`${this.downloadURL}?folderPath=${encodeURIComponent(this.props.folder.path)}`);
  }

  render() {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    return (
      <Link className="ems-item ems-folder-list-item" title='Open this folder' to={{ pathname: this.props.match.url, search: qs.stringify({ ...params, activeFolder: this.props.folder.path }, { addQueryPrefix: true }) }}>
        {/* <div className="thumbnail folder-list-item-thumbnail" style={{ backgroundImage: `url(${this.props.folder.thumbnail})` }}>
        </div> */}
        <div className="ems-item-inner">
        <div className="ems-title ems-folder-list-item-title">{this.props.folder.name}</div>
        {
          ((this.props.activeShare && (!this.props.activeShare.stream || Object.keys(this.props.activeShare.stream).length == 0)) || (!this.props.activeShare)) && !this.props.activeShare.watermark &&
          <button title="Download this folder" className="ems-button ems-button-icon ems-folder-list-item-button" onClick={e => this.download(e)}><i className="fas fa-arrow-down"></i></button>
        }
        </div>
      </Link>
    );
  }
}

export default withRouter(FolderListItem);
