import React, { Component } from "react";
import api from "../../configs/api";
import './PDFPreview.css';

class PDFPreviwer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      preview: null,
    };
  }

  goPage(pageNumber) {
    if (
      parseInt(pageNumber) <= parseInt(this.props.asset.metadata.numberOfPreviewPages) &&
      parseInt(pageNumber) > 0
    ) {
      this.setState({ currentPage: parseInt(pageNumber) }, () => {
        this.loadPreview();
      });
    }

    if(pageNumber === '') {
      this.setState({ currentPage: '' });
    }
  }

  get prevPage() {
    return this.state.currentPage - 1;
  }

  get nextPage() {
    return this.state.currentPage + 1;
  }

  componentDidMount() {
    this.loadPreview();
  }

  loadPreview() {
    let preview = this.props.token ? api.getPrivatePreviewURL(this.props.asset.id, this.props.token, this.state.currentPage - 1) : api.getPreviewURL(this.props.asset.id, this.state.currentPage - 1);
    this.setState({
      preview
    });

    if (this.state.currentPage < parseInt(this.props.asset.metadata.numberOfPreviewPages)) {
      this.loadImage(this.props.token ? api.getPrivatePreviewURL(this.props.asset.id, this.props.token, this.nextPage - 1) : api.getPreviewURL(this.props.asset.id, this.nextPage - 1))
    }
  }

  loadImage(src) {
    const img = new Image();
    img.src = src
  }

  render() {
    return (
      <div className="ems-pdf-preview-container">
        <img
          className="ems-pdf-preview-image"
          alt="preview"
          src={this.state.preview}
        />

        <div className="ems-pdf-preview-image-pagination" onClick={e => e.stopPropagation()}>
          <button
            className="ems-button ems-pdf-preview-image-pagination-prev"
            onClick={(e) => {
              e.stopPropagation();
              this.goPage(this.prevPage);
            }}
          >
            {" "}
            prev{" "}
          </button>
          <div className="ems-pdf-preview-image-pagination-input-container">
            <input
              onChange={(e) => {
                this.goPage(e.currentTarget.value);
              }}
              type="text"
              className="ems-pdf-preview-image-pagination-input"
              value={this.state.currentPage}
            />
            / {this.props.asset.metadata.numberOfPreviewPages}
          </div>
          <button
            className="ems-button ems-pdf-preview-image-pagination-next"
            onClick={(e) => {
              e.stopPropagation();
              this.goPage(this.nextPage);
            }}
          >
            {" "}
            next{" "}
          </button>
        </div>
      </div>
    );
  }
}

export default PDFPreviwer;
