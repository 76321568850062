import React, { Component } from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query'
import './App.css';
import api from './configs/api';
import Hierarchies from './containers/Hierarchies/Hierarchies';
import Shares from './containers/Shares/Shares';
import Articles from './containers/Articles/Articles';
import Article from './containers/Article/Article';
import AuthPage from './pages/AuthPage';
import Basket from './containers/Basket/Basket';
import ItemView from './containers/ItemView/ItemView';
import ShareItemView from './containers/ShareItemView/ShareItemView';
import Contribute from './containers/Contribute/Contribute';
import { AppStateConsumer } from './providers/AppState';
import customStyles from './helpers/customStyles';
import Redirections, { RedirectedPaths } from './pages/Redirections';
import { useSiteSettings } from './hooks/useSiteSetting';
import LoadingIcon from './components/LoadingIcon/LoadingIcon';

const queryClient = new QueryClient()

const PrivateRoute = ({ component: Component, ...rest }) => {
  const siteSettings = useSiteSettings();

  const generalSettings = siteSettings?.data?.general;

  if (siteSettings.isLoading) {
    return (<div className='ems-general-loading'>
      <LoadingIcon/>
    </div>)
  }
  
  return (
    <Route
      {...rest}
      render={props =>
        (api.token || generalSettings.publicPortal) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

class App extends Component {  
  get settings() {
    if (this.props.settingsLoadingSuccess) {
      return {
        style: this.props.settings.find(({ name }) => name === 'style').config,
        generalSiteSettings: {
          ...this.props.settings.find(({ name }) => name === 'general').config,
          itemPerPage: parseInt(this.props.settings.find(({ name }) => name === 'general').config.itemPerPage, 10),
        },
      }
    }

    return null;
  }

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <Router>
          <div
            className={`ems-app ${this.settings && this.settings.generalSiteSettings.siteLayout == 'horizontal' ? 'ems-app--horizontal' : 'ems-app--vertical'}`}
            data-path={this.props.location?.pathname}
          >
            {this.settings && <Helmet>
              <link href={this.settings.style.font.fontList[0]} rel='stylesheet'/>
              <style type='text/css'>
                {customStyles(this.settings.style)}
              </style>

              {this.settings.generalSiteSettings.customStyles && <style type='text/css'>
                {this.settings.generalSiteSettings.customStyles}
              </style>}
              <title>{this.settings.generalSiteSettings.siteTitle}</title>
              <link rel='shortcut icon' href={this.settings.generalSiteSettings.siteFavicon}/> 
            </Helmet>}

            <Switch>
              <Route
                path={RedirectedPaths}
                component={Redirections}
              />
              <PrivateRoute
                path='/hierarchies'
                component={Hierarchies}
              />
              <Route
                path='/auth'
                component={AuthPage}
              />
              <Route
                path='/shares/private/:token'
                component={Shares}
              />
              <PrivateRoute
                path='/shares/:id'
                component={Shares}
              />
              <PrivateRoute
                path='/shares'
                component={Shares}
              />
              <PrivateRoute
                path='/article/:id'
                component={Article}
              />
              <PrivateRoute
                path='/articles/category/:name'
                component={Articles}
              />
              <PrivateRoute
                path='/articles'
                component={Articles}
              />
              <Route
                path='/basket'
                component={Basket}
              />
              <Route
                path='/item/:id'
                component={ItemView}
              />
              <Route
                path='/share-item/:share_id/:id'
                component={ShareItemView}
              />
              <Route
                path='/share-item/:id'
                component={ShareItemView}
              />
              <PrivateRoute
                path='/contribute'
                component={Contribute}
              />
              {this.settings && <Redirect
                to={{ pathname: this.settings.generalSiteSettings.homePage }}
              />}
            </Switch>
          </div>
        </Router>
      </QueryClientProvider>
    );
  }
}

const ConnectedApp = props => (
  <AppStateConsumer>
    {({ settings, settingsLoading, settingsLoadingSuccess }) => (
      <App
        {...props}
        settings={settings}
        settingsLoading={settingsLoading}
        settingsLoadingSuccess={settingsLoadingSuccess}
      />
    )}
  </AppStateConsumer>
);

export default ConnectedApp;
