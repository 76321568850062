const ratioCalc = (ratio) => {
  if (ratio === '1:1') {
    return 1;
  }

  if (ratio === '2:3') {
    return 1.5;
  }

  if (ratio === '3:2') {
    return 0.666666667;
  }

  if (ratio === '4:2') {
    return 0.5;
  }

  if (ratio === '2:4') {
    return 2;
  }

  if (ratio === '16:9') {
    return 0.5625;
  }

  if (ratio === '6:4') {
    return 0.666666667;
  }

  if (ratio === '4:6') {
    return 1.5;
  }
}

export default styles => `
  body {
    background-color: ${styles.colors.general.bgColor || "#f0f0f0"};
    color: ${styles.colors.general.fontColor || "#444"};
    font-size: ${styles.font.body.size || "14px"};
    font-family: ${styles.font.body.fontFamily || "sans-serif"};
    font-weight: 300;
    line-height: ${styles.font.body.lineHeight || "1.4em"};
    letter-spacing:0px;
  }

  figure {
    margin: 1em ${styles.responsive.spacings.generalPadding || 30}px;
  }
  hr {
    margin:${styles.responsive.spacings.generalPadding || 30}px; 0px;
  }
  

/* ------------------------------------------------------------ General spacing */
  .ems-padding{
    padding:${styles.responsive.spacings.generalPadding || 30}px;
  }
    .ems-main-inner,
    .ems-padding-horizontal {
      padding-left:${styles.responsive.spacings.generalPadding || 30}px;
      padding-right:${styles.responsive.spacings.generalPadding || 30}px;
    }
    .ems-padding-vertical {
      padding-top:${styles.responsive.spacings.generalPadding || 30}px;
      padding-bottom:${styles.responsive.spacings.generalPadding || 30}px;
    }

  .ems-margin-vertical {
    margin-top:${styles.responsive.spacings.generalPadding || 30}px;
    margin-bottom:${styles.responsive.spacings.generalPadding || 30}px;
  }
    .ems-margin-bottom {
      margin-bottom:${styles.responsive.spacings.generalPadding || 30}px;
    }
    .ems-margin-bottom-half {
      margin-bottom:${styles.responsive.spacings.generalPadding/2 || 15}px;
    }
    .ems-margin-top {
      margin-top:${styles.responsive.spacings.generalPadding || 30}px;
    }
    .ems-margin-top-half {
      margin-top:${styles.responsive.spacings.generalPadding/2 || 15}px;
    }
    .ems-margin-top-quarter {
      margin-top:${styles.responsive.spacings.generalPadding/4 || 7}px;
    }


/* ------------------------------------------------------------ Header/Nav */
  .ems-header {
    background: ${styles.colors.header.bgColor || "#085577"};
    background: ${styles.colors.header.bgColor || "linear-gradient(45deg, #0c3344 0%, #085577 100%)"};
    color: ${styles.colors.header.fontColor || "#fff"};
    padding-right:${styles.responsive.spacings.generalPadding/2 || 15}px;
  }
    .ems-header-nav-item {
      color: ${styles.colors.header.fontColor || "#e0e0e0"};
      background-color:rgba(255,255,255,0);
      transition: color 250ms linear, background-color 250ms linear;
    }
    .ems-header-nav-item:hover {
      color: ${styles.colors.header.fontHoverColor || ""};
      background-color:rgba(255,255,255,0.1);
    }  
    .ems-header--vertical .ems-header-logo {
      padding: ${styles.responsive.spacings.generalPadding/2 || 15}px 0px;
      margin-right:${styles.responsive.spacings.generalPadding || 30}px;
    }
    .ems-header--vertical .ems-header-site-logo {
      margin-right: ${styles.responsive.spacings.generalPadding || 30}px;
      padding-right:${styles.responsive.spacings.generalPadding || 30}px;
      width:auto;
      height:100%; 
    }
    .ems-header-nav--vertical > .ems-header-nav-item {
      padding: 0px ${styles.responsive.spacings.generalPadding/2 || 15}px;
    }
    .ems-header--horizontal {
      padding-left:0px;
      padding-right:0px;
    }
    .ems-header--horizontal .ems-header-logo {
      padding:${styles.responsive.spacings.generalPadding || 30}px;
      padding-bottom:${styles.responsive.spacings.generalPadding*2 || 60}px;
      width:100%;
      height:auto; 
    }
    .ems-header--horizontal .ems-header-site-logo {
      padding-bottom:${styles.responsive.spacings.generalPadding/2 || 15}px;
    }
    .ems-header-nav--horizontal {
      padding-bottom:${styles.responsive.spacings.generalPadding/2 || 15}px;
      width:100%;
    }
    .ems-header-nav--horizontal > .ems-heading-title {
      margin-bottom:0px;
      padding:0px ${styles.responsive.spacings.generalPadding || 30}px ${styles.responsive.spacings.generalPadding/4 || 7}px ${styles.responsive.spacings.generalPadding || 30}px;
    }
    .ems-header-nav--horizontal > .ems-header-nav-item  {
      padding:${styles.responsive.spacings.generalPadding/4 || 7}px ${styles.responsive.spacings.generalPadding || 30}px;
    }


/* ------------------------------------------------------------ Headings/P/Label */
  h1,h2,h3,h4,h5,h6,label, .ems-h1,.h2,.h3,.h4,.h5,.h6 {
    padding:0px;
    margin:0px;
    font-weight:400;
  }

  h1, .ems-h1, .ems-headline .ems-title {
    font-size: ${styles.font.h1.size || "32px"};
    font-family: ${styles.font.h1.fontFamily || "inherit"};
    line-height: ${styles.font.h1.lineHeight || "1.4em"};
    margin-bottom: ${styles.font.h1.bottomMargin || "0px"};
    text-transform: ${styles.font.h1.capitalise ? 'uppercase' : 'none'}
  }

  h2, .ems-h2, .ems-headline .ems-section, .ems-headline .ems-subtitle {
    font-size: ${styles.font.h2.size || "24px"};
    font-family: ${styles.font.h2.fontFamily || "inherit"};
    line-height: ${styles.font.h2.lineHeight || "1.4em"};
    margin-bottom: ${styles.font.h2.bottomMargin || "0px"};
    text-transform: ${styles.font.h2.capitalise ? 'uppercase' : 'none'}
  }

  h3, .ems-h3 {
    font-size: ${styles.font.h3.size || "20px"};
    font-family: ${styles.font.h3.fontFamily || "inherit"};
    line-height: ${styles.font.h3.lineHeight || "1.4em"};
    margin-bottom: ${styles.font.h3.bottomMargin || "0px"};
    text-transform: ${styles.font.h3.capitalise ? 'uppercase' : 'none'}
  }

  h4, .ems-h4 {
    font-size: ${styles.font.h4.size || "18px"};
    font-family: ${styles.font.h4.fontFamily || "inherit"};
    line-height: ${styles.font.h4.lineHeight || "1.4em"};
    margin-bottom: ${styles.font.h4.bottomMargin || "0px"};
    text-transform: ${styles.font.h4.capitalise ? 'uppercase' : 'none'}
  }

  h5, .ems-h5 {
    font-size: ${styles.font.h5.size || "16px"};
    font-family: ${styles.font.h5.fontFamily || "inherit"};
    line-height: ${styles.font.h5.lineHeight || "1.4em"};
    margin-bottom: ${styles.font.h5.bottomMargin || "0px"};
    text-transform: ${styles.font.h5.capitalise ? 'uppercase' : 'none'}
  }

  h6, .ems-h6 {
    font-size: ${styles.font.h6.size || "14px"};
    font-family: ${styles.font.h6.fontFamily || "inherit"};
    line-height: ${styles.font.h6.lineHeight || "1.4em"};
    margin-bottom: ${styles.font.h6.bottomMargin || "0px"};
    text-transform: ${styles.font.h6.capitalise ? 'uppercase' : 'none'}
  }

  label, .ems-label {
    font-size: ${styles.font.label.size || "12px"};
    font-family: ${styles.font.label.fontFamily || "inherit"};
    line-height: ${styles.font.label.lineHeight || "1.4em"};
    margin-bottom: ${styles.font.label.bottomMargin || "0px"};
    text-transform: ${styles.font.label.capitalise ? 'uppercase' : 'none'}
  }

  p, .ems-p {
    margin-bottom: ${styles.font.body.pTagBottomMargin || "15px"};
  }


/* ------------------------------------------------------------ A Tag */
  a, a:hover {
    background-color: transparent;
    color: ${styles.colors.general.fontColor || "#009ee3"};
    text-decoration:none;
  }
  a:active,a:hover {
    color: ${styles.colors.general.fontHoverColor || "#28a9ff"}
  }


/* ------------------------------------------------------------ Width Control */
  .ems-main {
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
  }
  .ems-main-inner {
    width: 100%;
    height:100%;
    max-width: ${styles.responsive.breakpoints.desktop || 1281}px;
    position:relative;
  }

/* ------------------------------------------------------------ Items */
  .ems-item {
    padding:${styles.responsive.spacings.generalPadding/4 || 7}px;
  }


/* ------------------------------------------------------------ metafields */
  .ems-metadata-field {
    opacity:0.5;
    padding-right:${styles.responsive.spacings.generalPadding/4 || 7}px;
    font-weight:400;
    display: inline-flex;
  }
  .ems-metadata-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

/* ------------------------------------------------------------ Inputs */
  button, .ems-button, .ems-button:hover {
    font-size: ${styles.buttons.main.fontSize || "14px"};
    line-height: ${styles.buttons.main.height || "40px"};
    padding: 0px ${styles.buttons.main.horizontalPadding || "20px"};
    border-radius: ${styles.buttons.main.roundedCorners || "4px"};
    background-color: ${styles.colors.generalButtons.bgColor || "rgba(0,0,0,0.1)"};
    color: ${styles.colors.generalButtons.fontColor || "rgba(0,0,0,0.5)"};
    white-space: nowrap;
    outline: none;
    border: none;
    transition: color 250ms linear, background-color 250ms linear; 
  }
    .ems-button:hover {
      background-color: ${styles.colors.generalButtons.bgHoverColor || "#009ee3"};
      color: ${styles.colors.generalButtons.fontHoverColor || "#fff"};
    }
    button.cancel, .ems-button.cancel:hover {
      background-color: ${styles.colors.cancelButtons.bgColor || "#c22c00"};
      color: ${styles.colors.cancelButtons.fontColor || "#fafafa"};
    }
    .ems-button.cancel:hover {
      background-color: ${styles.colors.cancelButtons.bgHoverColor || "b22b00"};
      color: ${styles.colors.cancelButtons.fontHoverColor || "#fff"};
    }
    .ems-button:disabled, .ems-button:disabled:hover {
      opacity: 0.5;
      background-color: ${styles.colors.generalButtons.bgColor || "rgba(0,0,0,0.1)"};
      color: ${styles.colors.generalButtons.fontColor || "rgba(0,0,0,0.5)"};
    }
    .ems-button-icon, .ems-button-icon:hover {
      background:transparent;
      padding:0px;
      line-height:1em;
    }
    .ems-button-icon:hover {
      color:${styles.colors.generalButtons.bgHoverColor || "#009ee3"};
    }
    .ems-button.asset-list-item-button, .ems-button.asset-list-item-button:hover {
      font-size: ${styles.buttons.asset.fontSize || "14px"};
      padding: 0px ${styles.buttons.asset.horizontalPadding/2 || "15px"} 0px 0px;
      border-radius: ${styles.buttons.asset.roundedCorners || "4px"};
    }
    .ems-button-stack {
      position:relative;
      text-align:center;
    }
    .ems-button-overlay {
      position:absolute;
      top:0%;
      left:0%;
      display:block;
      transform:scale(1.8);
      text-shadow:0px 0px 1px #fff;
    }



    /* ------------------------------------------------------------ Basket */
    .ems-basket-tabs-item-active {
      background-color: ${styles.colors.generalButtons.bgHoverColor || "#009ee3"};
    }



    /* ------------------------------------------------------------ Pill */
    .ems-pill, .ems-pill:hover {
      font-size: ${styles.buttons.main.fontSize || "14px"};
      padding: ${styles.responsive.spacings.generalPadding/4 || 7}px;
      margin: 0px ${styles.responsive.spacings.generalPadding/4 || 7}px ${styles.responsive.spacings.generalPadding/4 || 7}px 0px;
      border-radius: ${styles.buttons.main.roundedCorners || "4px"};
      background-color: ${styles.colors.generalButtons.bgColor || "rgba(0,0,0,0.1)"};
      color: ${styles.colors.generalButtons.fontColor || "rgba(0,0,0,0.5)"};
      white-space: nowrap;
      outline: none;
      border: none;
      transition: color 250ms linear, background-color 250ms linear; 
    }
    .ems-pill:hover, .ems-pill.active {
      background-color: ${styles.colors.generalButtons.bgHoverColor || "#009ee3"};
      color: ${styles.colors.generalButtons.fontHoverColor || "#fff"};
    }



/* ------------------------------------------------------------ Inputs */
  .ems-input {
    font-size: ${styles.buttons.main.fontSize || "14px"};
    line-height: calc( ${styles.buttons.main.height || "40px"} - 2px);
    height: ${styles.buttons.main.height || "40px"};
    padding: 0px ${styles.buttons.main.horizontalPadding || "20px"};
    border-radius: ${styles.buttons.main.roundedCorners || "4px"};
    background-color: #fff;
    outline: none;
    border: 1px solid rgba(0,0,0,0.05);
    box-shadow:0px 4px 8px -4px rgba(0,0,0,0.03) inset;
    transition: color 250ms linear, background-color 250ms linear; 
  }
    .ems-input:hover {
      border-color: rgba(0,0,0,0.1);
    }
    .ems-input:focus {
      outline: none;
    }



/* ------------------------------------------------------------ Search bar */
  .ems-search-bar-input {
    font-size: ${styles.search.bar.fontSize || "14px"};
    height: ${styles.search.bar.height || "40px"};
    line-height: ${styles.search.bar.height || "40px"};
    padding: 0px calc( ${styles.search.bar.horizontalPadding || 30}px / 2);
    border-radius: ${styles.search.bar.roundedCorners || "4px"} 0px 0px ${styles.search.bar.roundedCorners || "4px"};
    border:none;
  }
    .ems-search-bar-clear-button, .ems-search-bar-clear-button:hover,
    .ems-search-bar-button , .ems-search-bar-button:hover{
      font-size: ${styles.search.button.fontSize || "14px"};
      height: ${styles.search.button.height || "40px"};
      line-height: ${styles.search.button.height || "40px"};
      padding: 0px calc( ${styles.search.button.horizontalPadding || 30}px / 2);
      border-radius: 0px ${styles.search.button.roundedCorners || "4px"} ${styles.search.button.roundedCorners || "4px"} 0px;
    }
    .ems-search-bar-clear-button, .ems-search-bar-clear-button:hover {
      border-radius: 0px;
      background:#fff;
      color: ${styles.colors.cancelButtons.bgColor || "#c22c00"};
    }





/* ------------------------------------------------------------ Hierarchies */
  .ems-hierarchies-list {
    font-size: ${styles.hierarchies.results.fontSize || "14px"};
  } 
    .ems-hierarchies-list-toggle {
      margin-left: ${styles.responsive.spacings.generalPadding/2 || 15}px;
    }
    .ems-hierarchies-list-wrapper {
      margin-left: -${styles.responsive.spacings.generalPadding/4 || 7}px;
      margin-right: -${styles.responsive.spacings.generalPadding/4 || 7}px;
    } 
    .ems-hierarchies-list-item-thumbnail-inner {
      padding-top: ${ratioCalc(styles.hierarchies.results.thumbnailAspectRatio)*100}%;
      background-size:cover;
    }



/* ------------------------------------------------------------ Sorting */ 

  .ems-sorting-title {
    font-size: ${styles.sort.sorting.labelFontSize || "12px"};
    line-height: ${styles.sort.sorting.height || "1.4em"};
    padding-right:${styles.responsive.spacings.generalPadding/2 || 15}px;
  }
  .ems-sorting-selector {
    font-size: ${styles.sort.sorting.dropdownFontSize || "12px"};
  }


/* ------------------------------------------------------------ Filters */ 
  .ems-filter-toggle, .ems-filter-toggle:hover {
    font-size: ${styles.filter.button.fontSize || "12px"};
    line-height: ${styles.filter.button.height|| "1.4em"};
  }
  .ems-filter-toggle i{
    margin-right:${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-filters-toggle-open{
    color:${styles.colors.generalButtons.bgHoverColor || "#009ee3"};
  }
  .ems-filter-list-inner {
    padding:${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-filter-list-item:not(:first-of-type) {
    padding-top:${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-filter-list-item-title {
    font-size: ${styles.filter.item.labelFontSize || "12px"};
    line-height: ${styles.filter.item.labelFontSize*1.4 || "1.4em"};
  }
  .ems-filter-list-item-values {
    font-size: ${styles.filter.item.fieldFontSize || "12px"};
    line-height: ${styles.filter.item.height || "1.4em"};
  }
  .ems-filter-list-item-value-checkbox {
    margin-right:${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-filter-list-item-values {
    column-gap:${styles.responsive.spacings.generalPadding/2 || 15}px;
  }


/* ------------------------------------------------------------ Asset */    
  
  .ems-asset-list-wrapper {
    margin-left: -${styles.responsive.spacings.generalPadding/4 || 7}px;
    margin-right: -${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-asset-list {
    font-size: ${styles.assets.results.fontSize || "12px"};
  }
    .ems-asset-list a{
      color: ${styles.colors.general.fontColor || "#444"};
    }
  .ems-asset-list-item-inner {
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.2);
    transition: border-color 0.5s ease-out;
  }
    .ems-asset-list-item:hover .ems-asset-list-item-inner{
      border-color: ${styles.colors.generalButtons.bgColor || "#009ee3"};
      transition: border-color 0.125s linear;
    }
  .ems-asset-list-item-thumbnail {
    background-color:rgba(0,0,0,0.1);
  }
    .ems-asset-list-item-thumbnail-inner {
      padding-top: ${ratioCalc(styles.assets.results.thumbnailAspectRatio)*100}%;
      background-size:contain;
    }
  .ems-asset-list-item-details, .ems-asset-list-item-footer {
    padding:${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-asset-list-message {
    margin: ${styles.responsive.spacings.generalPadding*2 || 60}px 0px;
  }




  /* ------------------------------------------------------------ Collections */
  .ems-asset-list-collection-item {
    padding:${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-asset-list-collection-item-inner {
    padding:${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-asset-list-collection-item-title {
    font-size: ${styles.buttons.main.fontSize || "14px"};
  }
  .ems-asset-list-collection-carousel-wrapper > .ems-button:hover {
    border-radius:50%;
    background-color: ${styles.colors.generalButtons.bgHoverColor || "#009ee3"};
    color: ${styles.colors.generalButtons.fontHoverColor || "#fff"};
  }
  .ems-asset-list-collection-carousel-wrapper .ems-asset-list-item {
    width:100%;
  }



  /* ------------------------------------------------------------ item */
  .ems-item-view-navigation {
    margin:${styles.responsive.spacings.generalPadding/2 || 15}px 0px ${styles.responsive.spacings.generalPadding || 30}px;
  }
  .ems-item-view-content {
    padding-bottom: ${styles.responsive.spacings.generalPadding || 30}px;
  }
  .ems-item-view-content-details {
    max-width: 50%;
    padding-left: ${styles.responsive.spacings.generalPadding || 30}px;
  }
  .ems-item-view-content-button + .ems-item-view-content-button {
    margin-left:${styles.responsive.spacings.generalPadding/4 || 7}px;
  }



  /* ------------------------------------------------------------ Shares */
  .ems-folder-list-toggle, .ems-folder-list-toggle:hover {
    padding-left:${styles.responsive.spacings.generalPadding/2 || 15}px;
  }
  .ems-shares-toggle, .ems-shares-toggle:hover {
    margin-left:${styles.responsive.spacings.generalPadding/2 || 15}px;
    display:flex;
    align-items:center;
  }



  /* ------------------------------------------------------------ Folders */
  .ems-folder-list-wrapper {
    margin-left: -${styles.responsive.spacings.generalPadding/4 || 7}px;
    margin-right: -${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-folder-list-item{

  }
  .ems-folder-list-item .ems-item-inner, .ems-folder-list-item:hover .ems-item-inner  {
    font-size: ${styles.buttons.main.fontSize || "14px"};
    padding: ${styles.responsive.spacings.generalPadding/4 || 7}px;
    border-radius: 0px ${styles.buttons.main.roundedCorners || "4px"} ${styles.buttons.main.roundedCorners || "4px"} ${styles.buttons.main.roundedCorners || "4px"};
    background-color: ${styles.colors.generalButtons.bgColor || "rgba(0,0,0,0.1)"};
    color: ${styles.colors.generalButtons.fontColor || "rgba(0,0,0,0.5)"};
    white-space: nowrap;
    outline: none;
    border: none;
    transition: color 250ms linear, background-color 250ms linear; 
  }
  

  .ems-folder-list-item .ems-item-inner:before {
    border-radius: ${styles.buttons.main.roundedCorners || "4px"} ${styles.buttons.main.roundedCorners || "4px"} 0px 0px;
    background-color: ${styles.colors.generalButtons.bgColor || "rgba(0,0,0,0.1)"};
    content:" ";
    display:block;
    position:absolute;
    bottom:100%;
    left:0px;
    width:25%;
    height:${styles.responsive.spacings.generalPadding/4 || 7}px;
    transition: color 250ms linear, background-color 250ms linear;
  }
  .ems-folder-list-item:hover .ems-item-inner, .ems-folder-list-item.active .ems-item-inner,.folder-list-item:hover .ems-item-inner:before{
    background-color: ${styles.colors.generalButtons.bgHoverColor || "#009ee3"};
    color: ${styles.colors.generalButtons.fontHoverColor || "#fff"};
  }
  .ems-folder-list-item-title {
    padding: ${styles.responsive.spacings.generalPadding/2 || 15}px ${styles.responsive.spacings.generalPadding/2 || 15}px;
  }
  .ems-button.folder-list-item-button:hover {
    color:${styles.colors.generalButtons.bgHoverColor || "#009ee3"};
    background-color:${styles.colors.generalButtons.fontHoverColor || "#fff"};
    border:1px solid ${styles.colors.generalButtons.fontHoverColor || "#fff"};
  }
  .ems-folder-list-message {
    padding-left:${styles.responsive.spacings.generalPadding/4 || 7}px;
  }





  /* ------------------------------------------------------------ Articles */
  .ems-article-list a{
    color: ${styles.colors.general.fontColor || "#444"};
  }
  .ems-article-list {
    margin-left: -${styles.responsive.spacings.generalPadding/4 || 7}px;
    margin-right: -${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-articles-category-toggle {
    margin-left:${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-articles-category-list {
    display:flex;
  }
  .ems-article-list-item-inner {
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.2);
    transition: border-color 0.5s ease-out;
  }
    .ems-article-list-item:hover .ems-article-list-item-inner{
      border-color: ${styles.colors.generalButtons.bgColor || "#009ee3"};
      transition: border-color 0.125s linear;
    }
  .ems-article-list-item-thumbnail {
    background-color:rgba(0,0,0,0.1);
  }
    .ems-article-list-item-thumbnail-inner {
      padding-top: ${ratioCalc(styles.assets.results.thumbnailAspectRatio)*100}%;
      background-size:contain;
    }
  .ems-article-list-item-details {
    padding:${styles.responsive.spacings.generalPadding/4 || 7}px;
    width:75%;
    font-size: ${styles.articles.results.fontSize};
  }
  .ems-article-list-item-description {
    padding-top:${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  

  /* ------------------------------------------------------------ Article */
  .ems-article-wrapper {
    padding: 0 ${styles.responsive.spacings.generalPadding/2 || 15}px;
    max-width: 714px;
  }

  /* ---------------------- Content Station CSS rules */

  .ems-article-wrapper .ems-text {
    margin: 0px auto ${styles.responsive.spacings.generalPadding/2 || 15}px auto;
  }
  .ems-hero-js .ems-_hero-content-box .ems-_hero-content-wrapper .ems-_hero-content-body {
    max-width: 714px;
    padding: 0 ${styles.responsive.spacings.generalPadding/2 || 15}px;
  }
  .ems-hero-js.hero-js-android .ems-_hero-content-box {
    margin: -${styles.responsive.spacings.generalPadding/2 || 15}px;
  }
  .ems-author {
    margin: ${styles.responsive.spacings.generalPadding/2 || 15}px auto;
  }
  .ems-author .ems-info-block {
    padding-top: ${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-author .ems-info-block .ems-sep_name_loc {
    width: ${styles.responsive.spacings.generalPadding/2 || 15}px;
  }
  .ems-body {
    max-width: unset;
  }
  .ems-article-wrapper .ems-container {
    margin: ${styles.responsive.spacings.generalPadding || 30}px 0;
  }
  .ems-embed {
    margin: ${styles.responsive.spacings.generalPadding/2 || 15}px auto;
  }
  .ems-article-wrapper .ems-footer {
    margin-top: ${styles.responsive.spacings.generalPadding || 30}px;
    margin-bottom: ${styles.responsive.spacings.generalPadding/2 || 15}px;
  }
  .ems-headline .ems-section {
    margin: ${styles.responsive.spacings.generalPadding || 30}px auto ${styles.responsive.spacings.generalPadding/2 || 15}px auto;
  }
  .ems-headline .ems-title {
    margin: ${styles.responsive.spacings.generalPadding || 30}px auto ${styles.responsive.spacings.generalPadding/2 || 15}px auto;
  }
  
  .ems-headline .ems-subtitle {
    margin: ${styles.responsive.spacings.generalPadding/2 || 15}px auto;
  }
  .ems-image {
    margin: ${styles.responsive.spacings.generalPadding/2 || 15}px auto;
  }
  .ems-image._left {
    width: calc(41% - ${styles.responsive.spacings.generalPadding/2 || 15}px);
    margin-right: ${styles.responsive.spacings.generalPadding/2 || 15}px;
  }
  .ems-image._right {
    width: calc(60% - ${styles.responsive.spacings.generalPadding/2 || 15}px);
    margin-left: ${styles.responsive.spacings.generalPadding/2 || 15}px;
  }
  .ems-intro {
    margin-top: ${styles.responsive.spacings.generalPadding || 30}px;
    margin-bottom: ${styles.responsive.spacings.generalPadding || 30}px;
  }
  .ems-product {
    margin: ${styles.responsive.spacings.generalPadding || 30}px auto;
  }



  /* ------------------------------------------------------------ Play all */
  .ems-play-all-content {
    padding:0px ${styles.responsive.spacings.generalPadding || 30}px;
  }
  .ems-play-all-close-button,
  .ems-play-all-close-button:hover {
    padding:0px ${styles.responsive.spacings.generalPadding/2 || 15}px;
  }
  .ems-play-all-controls {
    padding:${styles.responsive.spacings.generalPadding/4 || 7}px 0px;
  }
  .ems-play-all-sidebar-playlist-header {
    padding:${styles.responsive.spacings.generalPadding/4 || 7}px 0px ${styles.responsive.spacings.generalPadding/4 || 7}px ${styles.responsive.spacings.generalPadding || 30}px;
  }
  .ems-play-all-control-button {
    margin: 0 ${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-play-all-sidebar-playlist-item {
    padding:${styles.responsive.spacings.generalPadding/4 || 7}px ${styles.responsive.spacings.generalPadding || 30}px;
  }
  .ems-play-all-sidebar-playlist-item-active, .ems-play-all-sidebar-playlist-item-active:hover,
  .ems-play-all-sidebar-playlist-item:hover {
    background-color:${styles.colors.generalButtons.bgHoverColor || "#009ee3"};
    color: ${styles.colors.generalButtons.fontHoverColor || "#fff"};
  }
 



  
  /* ------------------------------------------------------------ Pagination */
  .ems-pagination {
    font-size: ${styles.assets.pagination.fontSize || "14px"};
  }
  .ems-pagination-list-pages{
    margin:${styles.responsive.spacings.generalPadding/2 || 15}px;
  }
  .ems-pagination-list-item {
    margin: 0 ${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-pagination-select-title {
    margin-right:${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-pagination-select-total-pages-word {
    margin:0px ${styles.responsive.spacings.generalPadding/4 || 7}px;
  }


  /* ------------------------------------------------------------ Breadcrumb */
  .ems-breadcrumb-item {
    padding-right:${styles.responsive.spacings.generalPadding/4 || 7}px;
  }
  .ems-breadcrumb-item:not(:last-of-type)::after {
    padding-left:${styles.responsive.spacings.generalPadding/4 || 7}px;
  }


  /* ------------------------------------------------------------ Footer */
  .ems-footer {
    background-color: ${styles.colors.footer.bgColor};
    color: ${styles.colors.footer.fontColor};
  }
    .ems-footer a, .ems-footer a:hover {
      color: ${styles.colors.footer.fontColor}
    }
    .ems-footer a:hover {
      color: ${styles.colors.footer.fontHoverColor}
    }



  /* ------------------------------------------------------------ Sign in form */
  .ems-sign-in-form, .ems-reset-password-form {
    background-color: ${styles.colors.general.bgColor || "rgba(255,255,255,0.5)"};
    box-shadow:0px 8px 8px -4px rgba(0,0,0,0.25);
    padding:${styles.responsive.spacings.generalPadding || 30}px;
    margin:${styles.responsive.spacings.generalPadding || 30}px;
  }

  /* ------------------------------------------------------------ errors */
  .ems-error, .error{
    color: ${styles.colors.cancelButtons.bgColor || "#c22c00"};
  }




/* ------------------------------------------------------------ Media Queries */


/* ------------ Header for mobile menu. 
   TODO change the 1200 for the pixel value of when to trigger mobile */

  @media only screen and (max-width:1200px) {
    .ems-header--vertical .ems-header-nav-wrapper {
      background: ${styles.colors.header.bgColor || "#085577"};
      background: ${styles.colors.header.bgColor || "linear-gradient(45deg, #0c3344 0%, #085577 100%)"};
      transition: opacity 100ms ease-in-out, right 250ms ease-in-out;
    }
    .ems-header--vertical .ems-header-nav-wrapper .ems-header-nav-item {
      padding:${styles.responsive.spacings.generalPadding/2 || 15}px;
    }
  }








/* ------------------------ Desktop and Larger */

  @media only screen and (min-width:${styles.responsive.breakpoints.desktop || 1281}px) {

    .ems-article-list--column .ems-article-list-item {
      width: ${100/(styles.articles.numbers.desktop || 4)}%;
    }

    .ems-hierarchies-list-item {
      width: ${100/(styles.hierarchies.numbers.desktop || 4)}%;
    }
    .ems-asset-list-item {
      width: ${100/(styles.assets.numbers.desktop || 6)}%;
    }
    .ems-filter-list-item-values {
      column-count: 6;
    }

    .ems-hierarchy-banner-image-laptop, 
    .ems-hierarchy-banner-image-tablet, 
    .ems-hierarchy-banner-image-largePhone,
    .ems-hierarchy-banner-image-mediumPhone,
    .ems-hierarchy-banner-image-smallPhone {
      display: none;
    }
  }


/* --------------------- Perform for non Desktop */
  @media only screen (max-width:${styles.responsive.breakpoints.desktop-1 || 1280}px ) {
    .ems-main-inner {
      width: 100%;
    }
  }



/* ---------------------- Laptop */

  @media only screen and (min-width:${styles.responsive.breakpoints.laptop || 1025}px) and (max-width:${styles.responsive.breakpoints.desktop-1 || 1280}px ) {
 
    .ems-article-list--column .ems-article-list-item {
      width: ${100/(styles.articles.numbers.laptop || 4)}%;
    }

    .ems-hierarchies-list-item {
      width: ${100/(styles.hierarchies.numbers.laptop || 4)}%;
    }
    .ems-asset-list-item {
      width: ${100/(styles.assets.numbers.laptop || 5)}%;
    }
    .ems-filter-list-item-values {
      column-count: 5;
    }

    .ems-hierarchy-banner-image-desktop, 
    .ems-hierarchy-banner-image-tablet, 
    .ems-hierarchy-banner-image-largePhone,
    .ems-hierarchy-banner-image-mediumPhone,
    .ems-hierarchy-banner-image-smallPhone {
      display: none;
    }
  }



/* ------------------------- Tablet */

  @media only screen and (min-width: ${styles.responsive.breakpoints.tablet || 769}px) and (max-width: ${styles.responsive.breakpoints.laptop-1 || 1024}px ) {
    
    .ems-article-list--column .ems-article-list-item {
      width: ${100/(styles.articles.numbers.tablet || 3)}%;
    }
    .ems-hierarchies-list-item {
      width: ${100/(styles.hierarchies.numbers.tablet || 3)}%;
    }
    .ems-asset-list-item {
      width: ${100/(styles.assets.numbers.tablet || 4)}%;
    }
    .ems-filter-list-item-values {
      column-count: 4;
    }




    .ems-hierarchy-banner-image-desktop, 
    .ems-hierarchy-banner-image-laptop, 
    .ems-hierarchy-banner-image-largePhone,
    .ems-hierarchy-banner-image-mediumPhone,
    .ems-hierarchy-banner-image-smallPhone {
      display: none;
    }
  }



/* --------------------------- large phone */

  @media only screen and (min-width: ${styles.responsive.breakpoints.largePhone || 641}px) and (max-width: ${styles.responsive.breakpoints.tablet-1 || 768}px ) {

    .ems-article-list--column .ems-article-list-item {
      width: ${100/(styles.articles.numbers.largePhone || 2)}%;
    }
    .ems-hierarchies-list-item {
      width: ${100/(styles.hierarchies.numbers.largePhone || 2)}%;
    }
    .ems-asset-list-item {
      width: ${100/(styles.assets.numbers.largePhone || 3)}%;
    }
    .ems-filter-list-item-values{
      column-count: 3;
    }

    .ems-hierarchy-banner-image-desktop, 
    .ems-hierarchy-banner-image-laptop, 
    .ems-hierarchy-banner-image-tablet,
    .ems-hierarchy-banner-image-mediumPhone,
    .ems-hierarchy-banner-image-smallPhone {
      display: none;
    }
  }


/* ---------------------- medium phone */

  @media only screen and (min-width: ${styles.responsive.breakpoints.mediumPhone || 321}px) and (max-width: ${styles.responsive.breakpoints.largePhone-1 || 640}px) {

    .ems-article-list--column .ems-article-list-item {
      width: ${100/(styles.articles.numbers.mediumPhone || 2)}%;
    }
    .ems-hierarchies-list-item {
      width: ${100/(styles.hierarchies.numbers.mediumPhone || 1)}%;
    }
    .ems-asset-list-item {
      width: ${100/(styles.assets.numbers.mediumPhone || 2)}%;
    }
    .ems-filter-list-item-values {
      column-count: 2;
    }

    .ems-article-list-item-inner {
      flex-direction:column;
    }
    .ems-article-list-item-thumbnail, .ems-article-list-item-details {
      width:100%;
    }

    .ems-hierarchy-banner-image-desktop, 
    .ems-hierarchy-banner-image-laptop, 
    .ems-hierarchy-banner-image-tablet,
    .ems-hierarchy-banner-image-largePhone,
    .ems-hierarchy-banner-image-smallPhone {
      display: none;
    }
  }


  /* ---------------------- small phone */

  @media only screen and (max-width: ${styles.responsive.breakpoints.mediumPhone-1 || 320}px) {

    .ems-article-list--column .ems-article-list-item {
      width: ${100/(styles.articles.numbers.mediumPhone || 2)}%;
    }
    .ems-hierarchies-list-item, .ems-folder-list-item {
      width: ${100/(styles.hierarchies.numbers.mediumPhone || 1)}%;
    }
    .ems-asset-list-item {
      width: ${100/(styles.assets.numbers.mediumPhone || 1)}%;
    }
    .ems-filter-list-item-values {
      column-count: 2;
    }
    .ems-article-list-item-inner {
      flex-direction:column;
    }
    .ems-article-list-item-thumbnail, .ems-article-list-item-details {
      width:100%;
    }

    .ems-hierarchy-banner-image-desktop, 
    .ems-hierarchy-banner-image-laptop, 
    .ems-hierarchy-banner-image-tablet,
    .ems-hierarchy-banner-image-largePhone,
    .ems-hierarchy-banner-image-mediumPhone {
      display: none;
    }
  }
`;