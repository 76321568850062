import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import './Login.css';
import LoginForm from "./loginForm";
import { useLogin } from "./hooks";

const Login = ({ useProxy }) => {
  const loginMutation = useLogin({ useProxy });
  const history = useHistory();
  const location = useLocation();
  const [isLoggedOut] = useState(sessionStorage.userIdentifier ? true : false);

  useEffect(() => {
    sessionStorage.removeItem('userIdentifier');
  }, []);

  const onSubmit = (data) => {
    const credentials = {
      identifier: data.username,
      password: data.password,
    };

    loginMutation.mutate(credentials, {
      onSuccess: () => {
        const to = location?.state?.from?.pathname || "/";
        history.push(to);
      },
    });
  };

  return (
    <div className="ems-sign-in ems-main">
      <div className="ems-main-inner">
        <LoginForm
          requestStatus={{
            isLoading: loginMutation.isLoading,
            isError: loginMutation.isError,
            isSuccess: loginMutation.isSuccess,
            errorMessage: loginMutation.error?.body?.message
          }}
          uiStatus={{
            isLoggedOut
          }}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};

Login.defaultProps = {
  useProxy: false,
};

Login.propTypes = {
  useProxy: PropTypes.bool,
};

export default Login;
