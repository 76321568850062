import React, { Component } from 'react';
import { NavLink, Link, Redirect } from 'react-router-dom';
import './Articles.css';
import qs from 'qs';
import isEqual from 'lodash/fp/isEqual';
import api from '../../configs/api';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import SearchBar from '../../components/SearchBar/SearchBar';
import ArticleCategoryList from './ArticleCategoryList/ArticleCategoryList';

const PAGE_ITEM_LIMIT = 4;

class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ui: {
        settingsLoading: false,
        settingsLoadingSuccess: false,
        
        categoriesLoading: false,
        categoriesLoadingSuccess: false,
        
        articlesLoading: false,
        articlesLoadingSuccess: false,

        assetsLoading: false,
        assetsLoadingSuccess: true,

        showCategories: false,

        redirectToSignIn: false,
      },

      settings: {
        style: {},
      },
      categories: [],

      result: {
        articles: [],
        totalHits: 0
      },

      articleCategorySettings: [],

      basketList: localStorage.sharesBasket ? JSON.parse(localStorage.sharesBasket) : [],
    };
  }

  async componentDidMount() {
    try {
      await this.loadSettings();
      await this.loadCategories();
      await this.loadArticles();
    } catch(error) {
      console.log(error);
    }
  }

  async componentDidUpdate(prevProps) {
    const currentActiveCategoryId = this.props.match && this.props.match.params.name;
    const previousActiveCategoryId = prevProps.match && prevProps.match.params.name;
    const currentURLSearchParams = this.URLSearchParams;
    const previousURLSearchParams = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true });

    if (
      currentURLSearchParams.keyword !== previousURLSearchParams.keyword || 
      currentURLSearchParams.sortBy !== previousURLSearchParams.sortBy || 
      currentURLSearchParams.page !== previousURLSearchParams.page ||
      currentURLSearchParams.activeFolder !== previousURLSearchParams.activeFolder ||
      !isEqual(currentURLSearchParams.filters, previousURLSearchParams.filters) ||
      currentActiveCategoryId !== previousActiveCategoryId
    ) {
      try {
        await this.loadArticles();
      } catch(error) {
        console.log(error);
      }
    }
  }

  async loadSettings() {
    this.setState({
      ui: {
        ...this.state.ui,
        settingsLoading: true,
        settingsLoadingSuccess: false,
      }
    });

    try {
      const settingsList = await api.getSettings();
      const articleCategorySettings = await api.getArticleCategorySettings();

      const itemPerPage = parseInt(settingsList.find(({ name }) => name === 'general').config.itemPerPage, 10);
      const general = {
        ...settingsList.find(({ name }) => name === 'general').config,
        itemPerPage,
      };

      const sortingList = settingsList.find(({ name }) => name === 'assets-sorting-list').config.list;
      const assetMetadataList = settingsList.find(({ name }) => name === 'asset-item-metadata-list').config.list;
      const filterList = settingsList.find(({ name }) => name === 'share-filter-list').config.list;
      const style = settingsList.find(({ name }) => name === 'style').config;

      const settings = {
        general,
        sortingList,
        assetMetadataList,
        filterList,
        style,
      };

      this.setState({
        ui: {
          ...this.state.ui,
          settingsLoading: false,
          settingsLoadingSuccess: true,
        },
        settings,
        articleCategorySettings,
      });

      return settings;
    } catch(error) {
      console.log(error)
    }
  }

  async loadCategories() {
    this.setState({
      ui: {
        ...this.state.ui,
        categoriesLoading: true,
        categoriesLoadingSuccess: false,
      }
    });

    try {
      let categories = await api.getArticleCategories();

      this.setState({
        ui: {
          ...this.state.ui,
          categoriesLoading: false,
          categoriesLoadingSuccess: true,
        },
        categories
      });

      return categories;
    } catch(error) {
      if (error.status === 404) {
        this.setState({
          ui: {
            ...this.state.ui,
            categoriesLoading: false,
            categoriesLoadingSuccess: false,
          }
        });
      }

      if(error.status === 403) {
        this.setState({
          ui: {
            ...this.state.ui,
            redirectToSignIn: true,
          }
        })
      }

      console.log(error);
    }
  }

  async loadArticles() {
    this.setState({
      ui: {
        ...this.state.ui,
        articlesLoading: true,
        articlesLoadingSuccess: false,
      },
      result: {
        articles: [],
        totalHits: 0
      },
    });

    try {
      let articleResults = 
        await api.getArticles(
          this.activeCategory && this.activeCategory.name, 
          this.currentPage, 
          this.state.settings.style.articles.results.itemPerPage,
          this.URLSearchParams.keyword
        );
      
      let  articleImages = [];

      if(articleResults.length > 0) {
        articleImages = await api.getArticlesImages(articleResults.map(article => article.id));
      }

      const articles = articleResults.map(article => ({ images: articleImages[article.id], ...article }));

      this.setState({
        ui: {
          ...this.state.ui,
          articlesLoading: false,
          articlesLoadingSuccess: true,
        },
        result: {
          ...this.state.result,
          articles,
        }
      });

      return articles;
    } catch(error) {
      if (error.status === 404) {
        this.setState({
          ui: {
            ...this.state.ui,
            articlesLoading: false,
            articlesLoadingSuccess: false,
          }
        });
      }

      console.log(error);
    }
  }

  toggleCategories(e) {
    e.preventDefault();

    this.setState({
      ui: {
        ...this.state.ui,
        showCategories: !this.state.ui.showCategories,
      }
    });
  }

  get URLSearchParams() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
  }

  get activeCategory() {
    return this.props.match && this.state.categories.find(category => category.name === this.props.match.params.name);
  }

  reset() {
     const newParams = {
      ...this.URLSearchParams,
      keyword: '',
      filters: {},
      sortBy: '',
      page: 0,
    };

    const queryString = qs.stringify(newParams, { addQueryPrefix: true });
    this.props.history.push(queryString);
  }

  get articles() {
    return this.state.result.articles;    
  }

  get currentPage() {
    if(this.URLSearchParams.page) {
      return parseInt(this.URLSearchParams.page, 10);
    }

    return 0;
  }

  get prevPage() {
    if (this.currentPage > 0) {
      return this.currentPage - 1;
    }

    return 0;
  }

  get nextPage() {
    if (this.articles.length <= PAGE_ITEM_LIMIT) {
      return this.currentPage;
    } else {
      return this.currentPage + 1;
    }
  }

  handleSearch(params) {
    const newParams = {
      ...this.URLSearchParams,
      keyword: typeof params.keyword === 'undefined' ? this.URLSearchParams.keyword : params.keyword ,
      page: typeof params.page === 'undefined' ? this.URLSearchParams.page : params.page,
    };

    const queryString = qs.stringify(newParams, { addQueryPrefix: true });
    this.props.history.push(queryString);
  }

  reset() {
     const newParams = {
      ...this.URLSearchParams,
      keyword: '',
      page: 0,
    };

    const queryString = qs.stringify(newParams, { addQueryPrefix: true });
    this.props.history.push(queryString);
  }

  get currentCategorySettings() {
    const currentActiveCategoryId = this.props.match && this.props.match.params.name || 'All';
    const categoryOptions = this.state.articleCategorySettings;

    return this.state.articleCategorySettings.find(category => category.name === currentActiveCategoryId);
  }

  getMetadata(article, metadataPath) {
    const path = metadataPath.split('.');
    let value = '';

    path.forEach(key => {
      if(value) {
        value = value[key];
      } else {
        value = article[key];
      }
    });
    return value;
  }

  render() {
    if (this.state.ui.redirectToSignIn) {
      return <Redirect
        to={{
          pathname: '/sign-in',
          state: { from: this.props.location }
        }}
      />;
    }

    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    return (
      <div className='ems-container'>
        <Header
          token={this.props.match.params.token}
          settings={this.state.settings.general}
          isAuthenticated={true}
          basketList={this.state.basketList}/>
        <div className='ems-articles-wrapper ems-main'>
          <div className="ems-main-inner">
            <SearchBar
              keyword={queryParams.keyword}
              reset={() => this.reset()}
              search={keyword => this.handleSearch({ keyword, page: 0 })}/>
            <div className='ems-articles-results-wrapper'>
              <div className='ems-heading-wrapper ems-articles-heading-wrapper'>
                <h2 className='ems-heading articles-heading'>Articles</h2>
              </div>
              <ArticleCategoryList
                articleCategorySettings={this.state.articleCategorySettings}
                toggleCategories={e => this.toggleCategories(e)}
                ui={this.state.ui}
                categories={this.state.categories}/>

              <div className='ems-margin-top ems-article-list-wrapper'>
                <div className='ems-heading-wrapper ems-article-results-heading-wrapper'>
                  <h3 className='ems-heading ems-article-results-heading'>Available articles</h3>
                </div>
                <div className={`ems-article-list${this.state.ui.settingsLoadingSuccess && this.currentCategorySettings ? ` ems-article-list--${this.currentCategorySettings.listingForm} ems-article-list--divide-${this.currentCategorySettings.columnNumber}` : ''}`}>
                  <div className='ems-article-list-inner'>
                  {this.state.ui.articlesLoadingSuccess && this.articles.length === 0 && !this.state.ui.articlesLoading && <p className='ems-margin-top ems-margin-bottom ems-message ems-asset-list-message'>There are no items to display</p>}
                  {this.state.ui.articlesLoading && <p className='ems-margin-top ems-margin-bottom ems-message ems-asset-list-message'>Loading <i className="fas fa-circle-notch fa-spin"></i></p>}
                    {this.articles.map(article =>
                      <Link to={`/article/${article._id}`} key={article._id} className='ems-item ems-article-list-item'>
                        <div className="ems-item-inner ems-article-list-item-inner">
                          <div className='ems-thumbnail ems-article-list-item-thumbnail'>
                            <div className='ems-thumbnail-inner ems-article-list-item-thumbnail-inner' style={{ backgroundImage: `url(${article.images && article.images[0] && article.images[0].id ? api.getPreviewURL(article.images[0].id) : ''})` }}>
                            </div>
                          </div>
                          <div className='ems-article-list-item-details'>
                            <div className='ems-article-list-item-details-inner'>
                              <div className='ems-title ems-article-list-item-title'>{article.title}</div>
                              <div className='ems-description ems-article-list-item-description'>
                                {this.currentCategorySettings.itemMetadataList && this.currentCategorySettings.itemMetadataList.map(item => <div key={item.name}>{item.name}: {this.getMetadata(article, item.metadata)}</div>)}
                                
                                <p>{article.articleMetaData[0].ContentMetaData.PlainContent.length > 250 ?
                                `${article.articleMetaData[0].ContentMetaData.PlainContent.substring(0, 150)}...` :
                                article.articleMetaData[0].ContentMetaData.PlainContent}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='ems-margin-top ems-pagination-wrapper'>
              <div className='ems-pagination-list'>
                <div className="ems-pagination-list-buttons">
                  <NavLink to={`${this.props.match.url}?page=${this.prevPage}`} title='Previous page' className='ems-button ems-pagination-list-button'><i className='fas fa-angle-left'></i></NavLink>
                </div>
                <div className="ems-pagination-list-pages">
                  <div className='ems-pagination-list-item'>Current Page: {this.currentPage + 1}</div>
                </div>
                <div className="ems-pagination-list-buttons">
                  <NavLink to={`${this.props.match.url}?page=${this.nextPage}`} title='Next page' className='ems-button ems-pagination-list-button'><i className='fas fa-angle-right'></i></NavLink>
                </div>
              </div>
            </div>

          </div>
        </div>
        <Footer
          settings={this.state.settings.general}/>
      </div>
    );
  }
}

export default Articles;